import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import { toggleMiniCart } from "./SideCart";
import Constants from "../Utilities/Constants";
import Cookies from "js-cookie";
import GetCustomer from "./GetCustomer";
import axios from "axios";


const Header = () => {
    const { customer } = useContext(AppContext);

    return (

        <header>
            <TopMenu />
            <HeaderMain />
            <NavBar business={customer && customer.business}/>
        </header>
    )
}

const TopMenu = () => {

    return (
        <div className="d-flex justify-content-between header-top">

            <div className="d-flex m-2">
                <Link to="Page/אודות">אודותינו</Link>
                <span><svg height="10" width="10" xmlns="http://www.w3.org/2000/svg"><circle r="50" cx="10" cy="10" fill="green" /></svg></span>
                <Link to="Contact/צור-קשר">שירות לקוחות</Link>
                <span><svg height="10" width="10" xmlns="http://www.w3.org/2000/svg"><circle r="50" cx="10" cy="10" fill="green" /></svg></span>
                <Link to="Page/אזורי-משלוחים">אזורי חלוקה וזמני משלוח</Link>
            </div>
            <div className="d-flex m-2">
                <Link to="/Page/החברים-שלנו">החברים שלנו</Link>
                <span><svg height="10" width="10" xmlns="http://www.w3.org/2000/svg"><circle r="50" cx="10" cy="10" fill="green" /></svg></span>
                <Link to="/SignUp">הרשמה למועדון</Link>
            </div>

	    </div>
    )
}

const HeaderMain = () => {
    const { customer, setCustomer, setCart, setCartId } = useContext(AppContext);
    const [ searchResults, setSearchResults ] = useState([]);
    const [query, setQuery] = useState();
    const navigate = useNavigate();
    const [site, setSite] = useState();
    

    useEffect(() => {

        const fetchSite = async () => {

            try {
                const response = await axios.get( Constants.API_URL_GET_SITE);
                setSite(response.data);

            } catch (error) {
                console.log(error);
            }
        };
    
        fetchSite();

    }, []);


    const Search = async (str) => {
        if (str.length < 2) {
            setSearchResults([]);
        }
        else {
            setQuery(str);
            const url = `${Constants.API_URL_GET_PRODUCTS}/${str}`;

            const response = await axios.get(url);
            setSearchResults(response.data);    
        }
    }

    async function logoff () {
        Cookies.remove('customerid',customer.id);
        GetCustomer({setCustomer, setCart, setCartId});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var searchinput = document.getElementById('searchInputDesktop');
        searchinput.value = '';
        setSearchResults([]);
        navigate(`/Search/${query}`);
    }

    return (
        <div className="d-flex justify-content-between align-items-center ">
        <div className="headerRight"></div>

        <div className="d-flex justify-content-between align-items-center flex-grow-1">

            <div className="menuitem">
                <form name="formSearchDesktop" className="form-inline headerSearchFront" onSubmit={handleSubmit} method="post">
                    <div>
                        <input type="text" placeholder="חפש מוצר" id="searchInputDesktop" name="searchText" 
                        autoComplete="off" className="searchText" 
                        onChange={(e)=>Search(e.target.value)} />
                        <SearchList list={searchResults}/>
                    </div>
                    <input type="submit" className="left headerSearchSubmit" value="" />
                </form>
            </div>

            <div className="topMenu d-flex align-items-center p-2">
                {customer ? 
                <>
                <div className="m-1">
                {customer.picture ? 
                    <img className="rounded user-picture" src={customer.picture} alt={customer.customerName} />
                    :
                    <>
                    <svg width="24" height="24" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                    </svg>
                    <span className="m-1">{customer.customerName}</span>
                    </>
                }
                </div>
                <Link className="btn btn-success float-start mx-2" to="/Profile">אזור אישי</Link>
                <button className="btn btn-outline-secondary float-start mx-2" onClick={logoff} >התנתק</button>
                </>
                : 
                <>
                <Link className="btn btn-success float-start mx-2" to="/login">כניסה לרשומים</Link>
                <Link className="btn btn-primary mx-2" to="SignUp">  הרשמה  </Link>
                </>
                }

            </div>
        </div>
        <div className="headerLeft d-flex align-item-center">
            <button className="cart-icon p-0 border-0" onClick={toggleMiniCart} >
                <div className="totals">
                    <div id="showcart" className="TotalPrice">סה"כ <span id="TotalPrice"></span></div>
                    <div className="fly-to position-relative">
                        <svg width="32" height="32" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                        </svg>
                        <div id="TotalItems" ></div>
                    </div>
                </div>
            </button>
        </div>
        {site && 
        <div className="logo position-absolute px-3">
            <Link to="/"><img src={`${Constants.IMAGE_URL}${site.logo}`} alt="Logo" /></Link>
        </div>
        }
        </div>
    )

}

const SearchList = ({list}) => {

    return (
        <>
        {list &&  list.length > 0 &&
        <div className="easy-autocomplete-container" id="eac-container-searchInputDesktop">
            <ul  className="row searchBar" >
                {list.map((str) => {
                return (
                <li className="col-md-6" value={str.productUrl}>
                    <Link to={{pathname:`/Product/${str.productUrl}`}}>
                        <img src={`${Constants.IMAGE_URL}${str.image}`} alt={str.productName} /> {str.productName}
                    </Link>
                </li>
                )
                })}
            </ul>
        </div>
        }
        </>
    )
}

const NavBar = ({business}) => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        
        async function getCategories () {
            const url = Constants.API_URL_GET_CATEGORIES;
            var api = url;
    
            if (business) {
                api = `${url}?b=true`;
            }
    
            await fetch(api).then(response => response.json())
                .then(data => setCategories(data));
        }

        getCategories();
        console.log(categories);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [business])


    return (
        <div className="d-flex bg-gradient box-shadow header">
            <div className="headerRight"></div>
            <div className="mega-menu">
            {
                categories.map((item, index) => (
                    <div key={index}>
                        <Link to={{pathname:`/Category/${item.categoryUrl}`}}>{item.categoryName}</Link>
                        <div className="menu_drop" >
                            <div className="drop_title">{item.categoryName}</div>
                            <div className="clearfix column-2">
                            {
                                item.subCategories.map((node, idx) => (
                                    <div className="drop_col" key={idx}>
                                        <div className="drop_link">
                                            <Link className="dropdown-item" to={{pathname:`/Category/${item.categoryUrl}/${node.subCategoryUrl}`}}>{node.subCategoryName}</Link>
                                        </div>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                </div>
                ))
            }
                <div> 
                    <Link to={{pathname:`/Category/sale`}}>מבצעי השבוע</Link>
                 </div>
            </div>
        </div>
    )
}


export default Header;
