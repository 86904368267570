import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { AppContext } from "./AppContext";
import { format } from "date-fns";


const VerifyStock = ({data, setData, setComponent, yishuv}) => {
    const { cartId, setCartId, cart, setCart, customer } = useContext(AppContext);
    const [ order, setOrder ] = useState([]);
    const [ totalCart, setTotalCart ] = useState(0);
    const [ totalOrder, setTotalOrder ] = useState(0);
    const [ couponCode, setCouponCode ] = useState('');
    const [ coupon, setCoupon ] = useState(null);
    const [ tip, setTip ] = useState(null);
    const [ Verified, setVerified ] = useState(true);
    const [ clubDiscount, setClubDiscount ] = useState(0);
    const clubPercent =
       (customer.clubDue && customer.clubDue >= data.deliveryDate) ? customer.clubPercent : 0;


    useEffect(() => {

        const fetchCart = async () => {

            const url = Constants.API_URL_GET_STOCK;
            try {
                setCartId(cart[0].cartId)

                const response = await axios.post(url, {CartId: cart[0].cartId, Date: data.deliveryDate, YishuvId: data.yishuvId});

                const orderData = response.data;
                setOrder(orderData);
                
                const totalcart = orderData.reduce((a,v) =>  a = a + v.itemTotal , 0 );
                setTotalCart(totalcart);
                setVerified(true);

            } catch(error) {
                if (error.response) {
                    // document.getElementById("submitError").style.display= "block";
                } else {
                console.error(error.massage);
                }
            } 
        }

        fetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]) 

    
    useEffect(()=> {

        // Update coupon if cart has changed
        if (coupon && coupon.minOrder > totalCart) {
            // clear coupon
            setCoupon(null);
        }
        else 
            calcCoupon(coupon);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[totalCart])


    useEffect(() => {
        
        const handleCoupon = async () => {

            var code = couponCode;
    
            try {
                const url = Constants.API_URL_GET_COUPON;
                const response = await axios.post(url, {CouponCode: code, TotalCart: totalCart});
                
                const coupon = response.data;
                setCoupon(coupon);
                calcCoupon(coupon);
                
                document.getElementById("couponError").style.display= "none";
    
            } catch(error) {
                document.getElementById("couponError").style.display= "block";
            }         
        }
        
        couponCode ? handleCoupon() : setCoupon(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[couponCode]);
 

    useEffect(() => {

        const calcOrderTotal = async () => {

            // Calculate order total price
            ClubDiscount();
            var delivery = (totalCart < yishuv.freeDelivery) ? yishuv.deliveryFee : 0;
            var couponDiscount = (coupon) ? coupon.discount : 0;
            var tipamount = tip ? tip : 0;
            var totalorder = (totalCart - clubDiscount + delivery - couponDiscount + tipamount);
            setTotalOrder(totalorder);
        }      

        calcOrderTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[coupon,tip,totalCart])


    const adjustOrder = async () => {
        
        const url = `${Constants.API_URL_ADJUST_ORDER}/${cartId}`;
        
        try {
            const response = await axios.put(url);
            
            setOrder(response.data);
            setCart(response.data);

        } catch(error) {
            console.error(error.massage);
            }
    }
    
    const calcCoupon = async (c) => {

        var discount = 0;
        if (c) { 
            discount = (c.percent > 0) ? c.percent / 100 * totalCart : c.discount;
            setCoupon(values => ({...values, discount: discount}));
        }
    }
        
    const ClubDiscount = async () => {

        const clubTotal = order.reduce((acc, curr) => {
            return curr.product.rebate ? acc + curr.itemTotal : acc;
        }, 0);
        var discount = clubPercent * clubTotal / 100;
        setClubDiscount(discount);
    }
    
    async function submitForm() {

        // Complete additional fields for order submission

        var delivery = (totalCart < yishuv.freeDelivery) ? yishuv.deliveryFee : 0;
        var couponDiscount = (coupon) ? coupon.discount : 0;

        const updatedData = {
            ...data,
            customerId: customer.customerId,
            cartId: cartId.toString(),
            totalCart: totalCart,
            couponCode: couponCode,
            couponDiscount: couponDiscount,
            deliveryFee: delivery,
            tip: tip,
            totalOrder: totalOrder,
        };
        
        try {
            var orderId;
            const url = `${Constants.API_URL_CREATE_ORDER}`;
            await axios.post(url, updatedData)
                .then(response => orderId = response.data);
            const order = {
                    ...updatedData, orderId: orderId
            };

            setData(order);
                    // Log the updated data
        console.log('Updated data:', order);
            setComponent('D');

        } catch(error) {
            console.error(error);
        }
    

    }

    const Desktop = () => {

        return (
            <>
                <div className="d-flex justify-content-between border-bottom border-success">
                    <h4 className="">סל הקניות שלי</h4>
                    
                    <h4><button title="חזור" onClick={()=>setComponent('B')} >{'<<'}</button>
                    <button className="align-left" title="הבא" onClick={()=>setComponent('D')} >{'>>'}</button></h4>
                </div>
                <div className="fw-bold text-success">
                    {yishuv && <span>{yishuv.yishuvName}</span>}, &nbsp;
                    <span>יום {data.deliveryDay} {format(data.deliveryDate,'dd/MM/yyy')}, {data.deliveryTime}</span>
                </div>
                <br/>
                <div className="d-flex justify-content-center align-items-center">
                    <table className="table table-striped order-items">
                        <thead  className="text-center">
                        <tr>
                            <th>תמונה</th>
                            <th>שם המוצר</th>
                            <th>כמות</th>
                            <th>סה"כ</th>
                            <th>יתרת מלאי</th>
                            <th>הערות</th>
                        </tr>
                        </thead>
                        <tbody>
                            {order.map((item) => {
                            return (
                                <tr key={item.itemId} >
                                    <td className="image" ><img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/></td>
                                    
                                    <td>
                                        {console.log(item)}
                                        {item.product.productName}
                                        {/* {item.selectedIngredients != null && JSON.parse(item.selectedIngredients).map((ing, idx) => {
                                            return (
                                                <div key={idx} className="text-success">{ing.name}</div>
                                            )
                                        })} */}
                                        <div style={{fontSize: 12}}>
                                            {item.product.price.toFixed(2)} ל{item.unit.unitName}
                                        </div>
                                        {item.product.special && showIngredients(item.selectedIngredients)}
                                        <div className="smartphone notes text-primary">{item.itemNotes}</div>
                                    </td>

                                    <td >{item.itemQtty} {item.unit.unitName}</td>
    
                                    <td >{item.itemTotal.toFixed(2)}</td>
                                    
                                    <td >{item.itemStock}</td>
                                    
                                    <td>
                                        <Verify item={item} setVerified={setVerified} />
                                    </td>
                                </tr>
                            )
                            })}
                            
                            <tr>
                                <td colSpan="6" >{cart.length} מוצרים בהזמנה</td>
                            </tr>
                            <tr>
                                <td className="text-start" colSpan="3" >סה"כ הזמנה:</td>
                                <td className="content" colSpan="2">{totalCart.toFixed(2)}</td>
                            </tr>

                            {clubDiscount > 0 && 
                            <tr>
                                <td className="text-start" colSpan="3"> הנחת מועדון:</td>    
                                <td className="content" colSpan="3">{clubDiscount.toFixed(2)}-</td>
                            </tr>
                            }
                            {coupon && 
                            <tr>
                                <td className="text-start" colSpan="3"> קופון הנחה ({coupon.couponDescr}):</td>
                                <td className="content" colSpan="3">{coupon.discount.toFixed(2)}-</td>
                            </tr>
                            }
                            {tip > 0 && 
                            <tr>
                                <td className="text-start" colSpan="3"> טיפ לשליח:</td>    
                                <td className="content" colSpan="3">{tip.toFixed(2)}</td>
                            </tr>
                            }
                            {yishuv.deliveryFee && (yishuv.freeDelivery > totalCart) ?
                            <tr>
                                {(yishuv.freeDelivery > 0) ?
                                <td className="text-start" colSpan="3"> משלוח (חינם מעל {yishuv.freeDelivery} ש"ח):</td>
                                :    
                                <td className="text-start" colSpan="3"> משלוח:</td>
                                }
                                <td className="content" colSpan="3">{yishuv.deliveryFee.toFixed(2)}</td>
                            </tr>: <></>}
    
                            <tr>
                                <td className="text-start" colSpan="3" >סה"כ לתשלום:</td>
                                <td className="content" colSpan="3">{totalOrder.toFixed(2)} ₪</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                    { Verified &&
                    <>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="coupon" className="form-label">אם ברשותך קופון הנחה הכנס אותו כאן</label>
                            
                                <input id="coupon" className="form-control w-50" onBlur={(e)=>{setCouponCode(e.target.value)}} />
                        </div>
                        <div className="col-md-4">

                            <label htmlFor="coupon" className="form-label">טיפ לשליח</label>
                            <div className="d-flex justify-content-between">
                                <input id="tip" className="form-control w-50" onBlur={(e)=>setTip(parseInt(e.target.value))} />
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                            <button className="btn btn-success" style={{marginTop:33}} > החל </button>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-6 text-end">
                            <Link className="btn btn-success" to={'/Cart'} >עריכת סל הקניות</Link>
                        </div>  
                        <div className="col-md-6 text-start">
                            <button type="button" className="btn btn-primary" onClick={submitForm}>לתשלום</button>
                        </div>  
                    </div>
                    </>
                    }
                    { !Verified &&
                        <div className="col-md-6 ">
                            <button className="btn btn-primary" onClick={()=>adjustOrder()}>עדכן הזמנה</button>
                        </div>
                    }
                    

                <div className="text-end pe-3">
                    <span id="couponError" className="text-danger hidden">קופון לא נמצא</span>
                </div> 
            </>
    
        )
    
    }

    const Smart = () => {

        return (
            <>
                <div className="d-flex justify-content-between border-bottom border-success">
                    <h4 className="">סל הקניות שלי</h4>
                    
                    <h4><button title="חזור" onClick={()=>setComponent('B')} >{'<<'}</button>
                    <button className="align-left" title="הבא" onClick={()=>setComponent('D')} >{'>>'}</button></h4>
                </div>
                <div className="fw-bold text-success">
                    {yishuv && <span>{yishuv.yishuvName}</span>}, &nbsp;
                    <span>יום {data.deliveryDay} {format(data.deliveryDate,'dd/MM/yyy')}, {data.deliveryTime}</span>
                </div>
                <br/>
                <div className="d-flex justify-content-center align-items-center">
                    <table className="table table-striped order-items">
                        <thead  className="text-center">
                        <tr>
                            <th>תמונה</th>
                            <th >שם המוצר</th>
                            <th>כמות</th>
                            <th>סה"כ</th>
                            {/* <th>מלאי</th> */}
                        </tr>
                        </thead>
                        <tbody>
                            {order.map((item) => {
                            return (
                                <tr key={item.itemId} >
                                    <td className="image" ><img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/></td>
                                    <td  >
                                        {console.log(item)}
                                        {item.product.productName}
                                        {item.selectedIngredients != null && JSON.parse(item.selectedIngredients).map((ing, idx) => {
                                            return (
                                                <div key={idx} className="text-success">{ing.name}</div>
                                            )
                                        })}
                                        <div style={{fontSize: 12}}>
                                            {item.product.price.toFixed(2)} ל{item.unit.unitName}
                                        </div>
                                        {item.product.special && showIngredients(item.selectedIngredients)}
                                        <div className="smartphone notes text-primary">{item.itemNotes}</div>
                                    </td>
                                    <td >{item.itemQtty} {item.unit.unitName}</td>
    
                                    <td >{item.itemTotal.toFixed(2)}</td>
                                    {/* <td >{item.itemStock}</td> */}
                                    <td>
                                        <Verify item={item} setVerified={setVerified} />
                                    </td>
                                </tr>
                            )
                            })}
                            
                            <tr>
                                <td colSpan="5" >{cart.length} מוצרים בהזמנה</td>
                            </tr>
                            <tr>
                                <td className="text-start" colSpan="3" >סה"כ הזמנה:</td>
                                <td className="content" colSpan="2">{totalCart.toFixed(2)}</td>
                            </tr>
                            {clubDiscount > 0 && 
                            <tr>
                                <td className="text-start" colSpan="3"> הנחת מועדון:</td>    
                                <td className="content" colSpan="2">{clubDiscount.toFixed(2)}-</td>
                            </tr>
                            }
                            {coupon && 
                            <tr>
                                <td className="text-start" colSpan="3"> קופון הנחה ({coupon.couponDescr}):</td>
                                <td className="content" colSpan="2">{coupon.discount.toFixed(2)}-</td>
                            </tr>
                            }
                            {tip > 0 && 
                            <tr>
                                <td className="text-start" colSpan="3"> טיפ לשליח:</td>    
                                <td className="content" colSpan="2">{tip.toFixed(2)}</td>
                            </tr>
                            }
                            {yishuv.deliveryFee && (yishuv.freeDelivery > totalCart) ?
                            <tr>
                                {(yishuv.freeDelivery > 0) ?
                                <td className="text-start" colSpan="3"> משלוח (חינם מעל {yishuv.freeDelivery} ש"ח):</td>
                                :    
                                <td className="text-start" colSpan="3"> משלוח:</td>
                                }
                                <td className="content" colSpan="2">{yishuv.deliveryFee.toFixed(2)}</td>
                            </tr>: <></>}
    
                            <tr>
                                <td className="text-start" colSpan="3" >סה"כ לתשלום:</td>
                                <td className="content" colSpan="2">{totalOrder.toFixed(2)} ₪</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div >
                    { Verified &&
                    <>
                        <div>
                            <label htmlFor="coupon" className="form-label">אם ברשותך קופון הנחה הכנס אותו כאן</label>
                            <div className="d-flex">
                                <input id="coupon" className="form-control w-50" onBlur={(e)=>{setCouponCode(e.target.value)}} />
                            </div>
                            <label htmlFor="coupon" className="form-label">טיפ לשליח</label>
                            <div className="d-flex justify-content-between">
                                <input id="tip" className="form-control w-50" onBlur={(e)=>setTip(parseInt(e.target.value))} />
                                <button className="btn btn-success" > עדכן </button>
                            </div>
                        </div>
                        <br/>
                        <hr />
                        <div >

                            <button type="button" className="btn btn-primary" onClick={submitForm}>לתשלום</button>
                        </div>  
                  </>
                    }
                    { !Verified &&
                        <div className="text-start">
                            <button className="btn btn-primary" onClick={()=>adjustOrder()}>עדכן הזמנה</button>
                        </div>
                    }
                </div>
                <div className="text-end pe-3">
                    <span id="couponError" className="text-danger hidden">קופון לא נמצא</span>
                </div> 
            </>
    
        )
    
    }

    return (
        (window.innerWidth > 768)
        ? <Desktop />
        : <Smart />
    )
}

const Verify = ({item, setVerified}) => {
    
    if (item.itemStock && item.itemStock <= 0) {
        setVerified(false);
        return (
            <span className="text-danger">המוצר חסר במלאי ליום המשלוח</span>
        )    
    }
    else if (item.itemStock && (item.itemQtty > item.itemStock)) {
        setVerified(false);
        return (
            <span className="text-danger">נותרו {item.itemStock} במלאי</span>
        )
    }
}

const showIngredients = (ingredients) => {

    var ings = JSON.parse(ingredients);

    return (
        (ings != null) ?
            ings.map((ing, idx) => {
                return (
                    <div key={idx} className="text-success">{ing.ingredientName}</div>
                )
            })
        : ''
    )
}


export default VerifyStock;