import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation  } from "react-router-dom";
import { AppContextProvider } from './Components/AppContext';

import HomePage from "./Pages/HomePage"
import Header from "./Components/Header";
import SmartHeader from "./Components/SmartHeader";
import Footer from "./Components/Footer";
import SideCart from "./Components/SideCart";
import Category from "./Pages/Category";
import SignIn from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import SignUp from "./Pages/Signup";
import Profile from "./Pages/Profile";
import Cart from "./Pages/Cart";
import Cash from "./Pages/Cash";
// import Duplicate from "./Pages/Duplicate";
import SuccessPage from "./Pages/Success";
import ProductPage from "./Pages/ProductPage";
import Ingredients from "./Components/Indegrients";
import SearchProducts from "./Pages/SearchProducts";
import Page from "./Pages/Page";
import ThankYou from "./Pages/ThankYou";
import ContactForm from "./Pages/Contact";
import SmartFooter from "./Components/SmartFooter";


const ResponsiveComponent = ({ isLargeScreen }) => {
  return (
    <>
      {isLargeScreen 
      ? <>
        <Header />        
        <Footer /> 
       </> 
       : <>
        <SmartHeader />
        <SmartFooter />
        </>
       }
      </>
  );
};


const App = () => {

  return (
    <>
      <Router>
        <AppContextProvider>
            <Layout />
        </AppContextProvider>
      </Router>
    </>
  )
}

const Layout = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(() => window.innerWidth > 768);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsLargeScreen(window.innerWidth > 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
    {!(location.pathname === '/success') 
      ? <ResponsiveComponent isLargeScreen={isLargeScreen} />
      : <></>
      }
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Category/:category" element={<Category />} />
        <Route path="/Category/:category/:subcategory" element={<Category />} />
        <Route path="/Login" element={<SignIn />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/Signup" element={<SignUp />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Cash" element={<Cash />} />
        {/* <Route path="/Duplicate/:orderid" element={<Duplicate />} /> */}
        <Route path="/Success" element={<SuccessPage />} />
        <Route path="/Product/:productUrl" element={<ProductPage />} />
        <Route path="/Ingredients" element={<Ingredients />} />
        <Route path="/Search/:query" element={<SearchProducts />} />
        <Route path="/Page/:url" element={<Page />} />
        <Route path="/ThankYou/:orderid" element={<ThankYou />} />
        <Route path="/Contact/:url" element={<ContactForm />} />
      </Routes>
      <SideCart />
      </>
      )
}

export default App;
