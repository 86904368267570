import axios from "axios";
import Cookies from 'js-cookie';
import Constants from '../Utilities/Constants';
import { v4 as uuidv4 } from 'uuid';

 const GetCustomer = async ({setCustomer, setCart, setCartId}) => {

    const customerid = Cookies.get('customerid');
    if (customerid) {
        FetchCustomer(customerid).then(data => setCustomer(data));
        FetchCart(customerid).then(data => setCart(data));
        setCartId(customerid);
    }
    else {
    //   const userIp = await FetchUserIp();
    //   FetchCart(userIp).then(data => setCart(data));
    //   setCartId(userIp);
    //   setCustomer(null);

        var cartid = Cookies.get('localCartId');
        console.log(cartid);

        if (!cartid) {
            cartid = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
            Cookies.set('localCartId',cartid);
        }
        FetchCart(cartid).then(data => setCart(data));
        setCartId(cartid);
        setCustomer(null);
    }
}


// const FetchUserIp = async () => {

//     const url = Constants.API_URL_GET_USER_IP;
//     var result = await fetch(url).then(response => response.text());

//     console.log('fetchUserIp',result);
//     return result;
// }

const FetchCustomer = async (customerid) => {
    
    const url = `${Constants.API_URL_GET_CUSTOMER}/?id=${customerid}`;
    try {
        const response = await axios.get(url);
        return response.data;
    }
    catch(err) {
        console.log('Cannot read customer');
        return null;
    }
}

const FetchCart = async (cartid) => {
    
    const url = `${Constants.API_URL_GET_CART}/${cartid}`;
    try {
        const response = await axios.get(url);
        return response.data;
    }
    catch(err) {
        console.log('Cannot read cart');
        return null;
    }
}


export default GetCustomer;