import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import DeliveryAddress from "../Components/DeliveryAddress";
import DeliverySelect from "../Components/DeliverySelect";
import VerifyStock from "../Components/VerifyStock";
import Payment from "../Components/Payment";


const Cash = () => {

    const { customer } = useContext(AppContext);
    const [ component, setComponent ] = useState('A');
    const [ data, setData ] = useState('');
    const [ yishuv, setYishuv] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        async function Initialize() {

            setData({
                // phoneNo: customer.phoneNo,
                yishuvId: customer.yishuvId,
                address: customer.address,
                entrance: customer.entrance,
                intercom: customer.intercom,
                notes: customer.notes
            });

            console.log(data);
        }

        (customer != null) ? Initialize() :  navigate('/Login', { state: { from: location } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (

        <div className="container ">
            <div className="wrapper cash">
                <div className="m-1">
                { 
                    {
                    'A':
                        <DeliveryAddress data={data} setData={setData} setComponent={setComponent} setYishuv={setYishuv}/>,
                    'B':
                        <DeliverySelect  data={data} setData={setData} setComponent={setComponent}/>,
                    'C':
                        <VerifyStock  data={data} setData={setData} setComponent={setComponent}  yishuv={yishuv}/>,
                    'D':
                        <Payment data={data} setComponent={setComponent} />
                    }[component]
                }
                </div>
            </div>
        </div>
    )  
}


export default Cash;
