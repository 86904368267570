import React, { useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { AppContext } from "./AppContext";
import Constants from "../Utilities/Constants";


const Product = ({product}) =>  {
    
    const { cart, setCart, cartId} = useContext(AppContext);
    
    // if product in cart
    const cartitem = cart.find((p) => p.productId === product.id);
    product.cart = (cartitem) ? cartitem.itemQtty : 0;
    
    if (cartitem && (product.units.length > 1)) 
    {
        const newlist = product.units.map((x) => {
            return ((x.unitId === cartitem.unit.unitId) ? {...x, active: "active"} : {...x, active: ""});
        });
        product = {...product, units: newlist}; 
    }
    else product.units[0].active = 'active';

    // console.log(product);

    const Action = () => {

        return (
            <div className="actions">

                <Units units={product.units} />
    
                <div className="action-buttons d-flex justify-content-center">
                    <button type="button" onClick={increment}><i className="bi bi-plus-square" /></button>
                    <input onChange={() => null} type="text" id={product.id} className="product_qty" value={product.cart} />
                    <button type="button" onClick={decrement}><i className="bi bi-dash-square" /></button>
                </div>
    
            </div>
        )
    }

    const Units = ({units}) => {
        return (
            <div className="unit-types rounded-pill">
                { 
                    units.length > 1
                        
                        ? units.map((unit, idx) => {
                    
                            return (
                                <button key={idx} 
                                    className={`float-end rounded-pill units ${unit.active}`}
                                    style={{width: `${unit.active === 'active' ? '60%' : '40%' }`}} 
                                    onClick={() => toggleUnit(unit.unitId)}>
                                    {unit.unitName}
                                </button>
                            )

                        })
                        :  <div className="units rounded-pill active">{product.unit.unitName}</div>
                } 
                
            </div>
        )
    }

    const increment = () => {
        
        const unit = product.units.find((unit) => unit.active === 'active');
        var qtty = product.cart + unit.unitIncrement;
        updateDB(qtty);
    }
    
    const decrement = () => {

        const unit = product.units.find((unit) => unit.active === 'active');
        var newQtty = product.cart - unit.unitIncrement;
        (newQtty < 0 ) && (newQtty = 0);
        (product.cart > 0) && updateDB(newQtty) ;
    }

    async function updateDB (qtty) {

        // Update db cart product with new qtty.
        // Receive updated cart product.
        // Update cart list with updated product.
        const unit = product.units.find((unit) => unit.active === 'active');
        var unitid = unit.unitId;

        const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${cartId}`;
        // const data = {productId: product.id, unitId: unitid, itemQtty: qtty, special: product.special };
        const data = {productId: product.id, unitId: unitid, itemQtty: qtty};
        const headers = {
            'Content-Type': 'application/json',
        };

        const response = await axios.post(apiUpdate, data, {
            headers: headers
        });

        const exists = cart.find((x) => x.productId === product.id);
        
        if (exists) {
            (qtty === 0) ? handleRemove(exists.id) : handleUpdate(response.data);
        }
        else handleAdd(response.data);
    }

    async function handleUpdate(cartitem) {

        // Create new cart list with updated product
        const cartList = cart.map((x) => {
            return ((x.productId === cartitem.productId) ? cartitem : x);
        })

        setCart(cartList);
    }
    
    async function handleAdd(newItem) {
        
        // Concatenate new product to cart items
        const newList = [newItem, ...cart];
        setCart(newList);
    }
    
    async function handleRemove(id) {
        
        // Remove product from cart list
        const newList = cart.filter((x) => x.id !== id);
        setCart(newList);
    }

    const toggleUnit = (unitid) => {

        const newlist = product.units.map((x,idx) => {
            return ((x.unitId === unitid) ? {...x, active: "active"} : {...x, active: ""});
        });

        const unit = newlist.find((unit) => unit.active === 'active');
        if (Number.isInteger(unit.unitIncrement)) 
        {   
            product.cart = parseInt(product.cart);
        }

        updateDB(unitid, product.cart);
    }


    return (

        <div className="Product shadow" id={product.id} key={product.id}>
            <div className="card h-100 text-center" >
                <Link to={{pathname:`/Product/${product.productUrl}`}}>
                    {/* <img src={product.image} className="card-img-top" alt={product.productName} /> */}
                    {(product.image) 
                    ? <div style={{backgroundImage: `url("${Constants.IMAGE_URL}/${product.image}")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} className="card-img-top" alt={product.productName}></div>
                    : <div style={{backgroundImage: `url("${Constants.IMAGE_URL}/uploads/logo.webp")`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} className="card-img-top" alt={product.productName}></div>
                    }
                </Link>
               
                <div className="card-body">
                    <h5 className="card-title mb-0">{product.productName}</h5>
                    <div className="sale-text">{product.saleText}</div>
                    {(product.weight) ?
                        <span className="small-text">
                            {(product.salePrice != null) ? 
                                <> {(product.salePrice/product.weight*100).toFixed(2)} </> 
                            :   <> {(product.price/product.weight*100).toFixed(2)} </>}
                        </span> 
                        : <></>
                    }
                    <div className="sale-due">
                        {(product.saleDue != null) ? <span>בתוקף עד  {format(product.saleDue,'d/MM/yyyy')} </span> : <></>}
                    </div>
                    
                    
                    <div className="card-text">
                        {(product.salePrice != null) ? <> {salePrice(product)} </> : <> {itemPrice(product)} </>}
                    </div> 


                    <Action />
                </div>

                <div className="d-flex flex-row bd-highlight mb-3 labels">

                    {product.labels.map((label,idx) => {
                        return (                    
                            <img key={idx} className="me-md-1" src={`${Constants.SITE_URL}/${label.labelIcon}`} alt={label.labelName}/>
                        )
                        })}
                    
                </div>
                { (product.cart) ?  <span className="cart-qtty">{product.cart} </span> : <></> }
                    {product.description && 
                    <div className="product-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                        </svg>
                        <div className="details">
                            {/* <span className="close"></span> */}
                            <p>{product.description}</p></div>
                     </div>
                    }




            </div>
        </div>

    )
}


function itemPrice(product) {

    return (
        <>
        <span className="fw-bold px-1 fs-5">{Number.isInteger(product.price) ? product.price : product.price.toFixed(2)}</span>
        <span className="unit">{product.unit.unitName}</span>
        </>
    )
}


function salePrice(product) {
    
    return (
        <>
        <div>
            <span className="text-decoration-line-through">{Number.isInteger(product.price) ? product.price : product.price.toFixed(2)}</span>
            <span className="unit">{product.unit.unitName}</span>
        </div>

        <div className="sale-price">
            <span className="bold">{Number.isInteger(product.salePrice) ? product.salePrice : product.salePrice.toFixed(2)} ₪</span>
        </div> 
        </>
    )
}


export default Product;