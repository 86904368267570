import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { AppContext } from "../Components/AppContext";
import { format } from "date-fns";
import Product from "../Components/Product";
import IngredientsSelector from "../Components/Indegrients";


const ProductPage = () => {
    const { productUrl } = useParams();
    const [product, setProduct] = useState({});
    const { cart, cartId, setCart } = useContext(AppContext);

    useEffect(() => {

        const fetchProduct = async () => {
            
            try {
                const url = `${Constants.API_URL_GET_PRODUCT}/${productUrl}`;
                const response = await axios.get(url);
                getCartQtty(response.data);
            } catch (error) {
                console.error('Error fetching product:', error);
                alert("מוצר לא נמצא");
            }
        };

        fetchProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productUrl, cart]);

    const getCartQtty = (product) => {
        const cartitem = cart.find((p) => p.productId === product.id);

        if (cartitem) {
            const newproduct = { 
                ...product, 
                cart: cartitem.itemQtty, 
                userNotes: cartitem.itemNotes,
                units: product.units.length > 1 ? 
                    product.units.map((x) => 
                        x.unitId === cartitem.unit.unitId ? 
                        { ...x, active: "active" } : 
                        { ...x, active: "" }
                    ) : 
                    product.units
            };

            if (JSON.stringify(product) !== JSON.stringify(newproduct)) {
                setProduct(newproduct); 
            }
        } else {
            if (product.cart !== 0) {
                setProduct({ ...product, cart: 0 });
            }
        }
    };

    
    const increment = () => {

        const unit = (product.units.length > 1) ?
            product.units.find((unit) => unit.active === 'active')
            :
            product.unit;
        var newQtty = product.cart + unit.unitIncrement;
        updateDB(unit.unitId, newQtty);
    }
    
    const decrement = () => {

        const unit = (product.units.length > 1) ?
        product.units.find((unit) => unit.active === 'active')
        :
        product.unit;

        var newQtty = product.cart - unit.unitIncrement;
        (newQtty < 0 ) && (newQtty = 0);
        (product.cart > 0) && updateDB(unit.unitId, newQtty) ;
    }

    // const updateNotes = (e) => {
    //     updateDB(product.unit.unitId, product.cart, e.target.value);
    // }

    async function updateDB (unitid, itemQtty, notes) {

        // Update db cart product with new qtty.
        // Receive updated cart product.
        // Update cart list with updated product.

        const apiUpdate = `${Constants.API_URL_UPDATE_CART}/${cartId}`;
        const data = {productId: product.id, unitId: unitid, itemQtty: itemQtty, notes: notes };

        const response = await axios.post(apiUpdate, data);
        const exists = cart.find((x) => x.productId === product.id);
        
        if (exists) {
            (itemQtty === 0) ? handleRemove(exists.id) : handleUpdate(response.data);
        }
        else handleAdd(response.data);
    }

    async function handleUpdate(cartproduct) {

        // Create new cart list with updated product
        const cartList = cart.map((x) => {
            return ((x.productId === cartproduct.productId) ? cartproduct : x);
        })

        setCart(cartList);
    }
    
    async function handleAdd(newproduct) {
        
        // Concatenate new product to cart products
        const newList = [newproduct, ...cart];
        setCart(newList);
    }
    
    async function handleRemove(id) {
        
        // Remove product from cart list
        const newList = cart.filter((x) => x.id !== id);
        setCart(newList);
    }

    const toggleUnit = (unitid) => {

        const newlist = product.units.map((x,idx) => {
            return ((x.unitId === unitid) ? {...x, active: "active"} : {...x, active: ""});
        });

        const unit = newlist.find((unit) => unit.active === 'active');
        if (Number.isInteger(unit.unitIncrement)) 
        {   
            product.cart = parseInt(product.cart);
        }

        updateDB(unitid, product.cart);
    }

    const Units = ({product}) => {

        return (
        product &&
            <div className="unit-types rounded-pill mx-auto text-center">
            { 
                product.units?.length > 1
                    
                    ? product.units.map((unit, idx) => {
                
                        return (
                            <button key={idx} 
                                className={`float-end rounded-pill units ${unit.active}`}
                                style={{width: `${unit.active === 'active' ? '60%' : '40%' }`}} 
                                onClick={() => toggleUnit(unit.unitId)}>
                                {unit.unitName}
                            </button>
                        )
    
                    })
                    :  <div>{product.unit?.unitName}</div>
            }
            </div>
        )
    }
    
    const QttyAction = ({item, increment, decrement}) => {
        return (
        <div className="action-buttons d-flex justify-content-center">
            <button type="button" onClick={increment}><i className="bi bi-plus-square" /></button>
            <input onChange={() => null} type="text" id={item.id} className="product_qty" value={product.cart} />
            <button type="button" onClick={decrement}><i className="bi bi-dash-square" /></button>
        </div>
        )
    }


    return (
        product && 
        <>
        <div className="container">
        <div className="wrapper cash row" > 
        {(product.image) 
            ? <div className="col-md-5" style={{height: 300, backgroundImage: `url("${Constants.IMAGE_URL}${product.image}")`, backgroundSize: 'contain',  backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} alt={product.productName}></div>
            : <div className="col-md-5" style={{height: 300, backgroundImage: `url("${Constants.IMAGE_URL}/uploads/logo.webp")`, backgroundSize: '50%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} alt={product.productName}></div>
        }
            <div className="col-md-7 d-flex align-content-around flex-wrap" >
                <h2 className="border-bottom border-success w-100">{product.productName}</h2>
                <div>
                    {product.description} 
                    <br />    
                    {product.nutrition} 
                </div>
                <div className="w-100">
                    <div>
                        {(product.salePrice != null) ? <SalePrice product={product} /> : <ProductPrice product={product} />}
                        <div>
                            <span className="text-danger fs-4">{product.notes}</span> &nbsp;
                            <span>{(product.saleDue != null) ? <span>(בתוקף עד  {format(product.saleDue,'d/MM/yyyy')})</span> : <></>}</span>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    
                    <div className="actions d-flex ">
                        <QttyAction item={product} increment={increment} decrement={decrement} />
                        <Units product={product} />
                    </div>
                    <br/>
                    <br/>
                    
                    <div>
                        {/* <input className="w-100 text-danger" placeholder="הערה למוצר" 
                            defaultValue={product.userNotes} onBlur={updateNotes}>
                        </input> */}

                        <div className="d-flex justify-content-between flex-wrap" style={{fontSize:12}}>
                            <span>התמונה להמחשה בלבד</span>
                            <span>כל הנתונים על המוצר עפ"י הגדרות היצרן</span>
                            <span>מחיר המוצר נשמר בעת סגירת ההזמנה</span>
                        </div>
                        <br/>
                    </div>
                </div>
            </div>
        </div>
        { product.special && product.cart > 0 &&
        <IngredientsSelector productId={product.id} />
        }
            {product && product.relatedProducts && product.relatedProducts.length > 0 &&
            <>
            <h3 className="text-center">אולי יעניין אותך גם...</h3>
            <br/>
            <div className="d-flex flex-wrap justify-content-around">
            
                {product.relatedProducts.map((item, key) => {
                    return (
                        <Product product={item} key={key} />
                    )
                })}
            </div>
            </>
            }
        </div>
        </>
    )

}


const ProductPrice = ({ product }) => {
    return (
        <>
        <div className="d-flex align-items-end">
            <span className="fw-bold px-1 fs-5">{Number.isInteger(product.price) ? product.price : product.price?.toFixed(2)}</span>
            <span className="unit">ל{product.unit?.unitName}</span>
        </div>
        </>
    );
}

const SalePrice = ({ product }) => {
    return (
        <>
        <div className="d-flex align-items-end">
            <span className="text-decoration-line-through m-1">{Number.isInteger(product.price) ? product.price : product.price.toFixed(2)}</span>
            <span className="fw-bold fs-2 mx-2 text-danger">{Number.isInteger(product.salePrice) ? product.salePrice : product.salePrice.toFixed(2)}</span>
            <span className="unit m-1">₪ ל{product.unit?.unitName}</span>
        </div> 
        </>
    );
}



export default ProductPage;