import React, { useState, useContext, useEffect } from "react";
import { Navigate  } from "react-router-dom";
import { AppContext } from "./AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { format } from "date-fns";
import { Modal, Button } from 'react-bootstrap';
import Order from "./Order";
import Invoice from "./Invoice";


const Orders = () => {
    const [orders, setOrders] = useState([]);
    const {customer, cartId, setCart} = useContext(AppContext);
    const [orderId, setOrderId]  = useState('');
    const [showOrderModal, setOrderModal] = useState(false);
    const [showInvoiceModal, setInvoiceModal] = useState(false);
    const [showDuplicateModal, setDuplicateModal] = useState(false);


    useEffect(() => {
    
        async function getOrders() {
            if (customer) {
                const url = `${Constants.API_URL_GET_ORDERS}/${customer.customerId}`;
    
                try {
                    const response = await axios.get(url);
        
                    setOrders(response.data);
                }
                catch(error) {
                    console.log(error.message);
                }
        
            }
            else {
                alert('נא להיכנס למערכת');
                Navigate('/Login');
            }
        }

        getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const handleClose = () => {
        setDuplicateModal(false)
        setOrderModal(false);
        setInvoiceModal(false);
    }

    const handleShow = () => setDuplicateModal(true);

    const handleSave = () => {

        updateCart();
        handleClose(); // Optionally close the modal after saving
    }

    async function updateCart() {
        const url = `${Constants.API_URL_LOAD_CART}/${cartId}/${orderId}`;

        try {
            const response =  await axios.get(url);
    
            setCart(response.data);
        }
        catch(error) {

            if (error.response) {
                alert('הזמנה לא נמצאה');
            } else
                alert(error.message);
        }
    }

    const OrderTable = () => {
        
        return (
            <table className="table table-striped">

                <thead>
                <tr className="head">
                    <th className="desktop">הזמנה</th>
                    <th>תאריך</th>
                    <th>סכום</th>
                    <th className="desktop">סטטוס</th>
                    <th className="text-center">הצג הזמנה</th>
                    <th className="text-center">העתק חשבון</th>
                    <th className="text-center">שחזר הזמנה</th>
                </tr>
                </thead>
            
                <tbody>
                    {orders.map((order, key) => {
                        return (
                            <OrderRow key={key} order={order} />
                        )
                    })}
                </tbody>
            
            </table>

        )
    }

    const OrderRow = ({order}) => {

        var formattedDate = format(order.orderDate, "dd/MM/yyyy");

        return (
            <tr >
                <td className="desktop">{order.orderId}</td>
                <td>{formattedDate}</td>
                <td>{order.totalOrder}</td>
                <td className="desktop">{order.status}</td>
                <td className="text-center">
                    <button onClick={() => viewOrder(order.orderId)} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 9h14V7H3v2zm0 4h14v-2H3v2zm0 4h14v-2H3v2zm16 0h2v-2h-2v2zm0-10v2h2V7h-2zm0 6h2v-2h-2v2z"/></svg>
                    </button>
                    </td>
                <td className="text-center">
                    {order.actualTotal && 
                    <button onClick={() => viewInvoice(order.orderId)} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><g><rect fill="none" height="24" width="24"/></g><g><path d="M4,7h16v2H4V7z M4,13h16v-2H4V13z M4,17h7v-2H4V17z M4,21h7v-2H4V21z M15.41,18.17L14,16.75l-1.41,1.41L15.41,21L20,16.42 L18.58,15L15.41,18.17z M4,3v2h16V3H4z"/></g></svg>
                    </button>
                    }
                </td>
                <td className="text-center">
                    <button onClick={()=>{setOrderId(order.orderId); handleShow();}} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#5f6368"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
                    </button>
                </td>
            </tr>
        )
    }

    const Duplicate = ({ show, handleClose, handleSave }) => {

        return (
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>שחזור הזמנה</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    שים לב:<br></br>
                    פעולה זו תנקה את סל הקניות ותטען מוצרים חדשים עפ"י ההזמנה שבחרת.<p></p>
                    לאישור הקש אישור, לביטול הקש בטל.
                </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                ביטול
                </Button>
                <Button variant="primary" onClick={handleSave}>
                אישור
                </Button>
            </Modal.Footer>
        </Modal>
        )
    }

    const viewOrder = (orderId) => {

        setOrderId(orderId);
        setOrderModal(true); 
    }
    
    const OrderModal = ({ show, orderId, handleClose }) => {

        return (
            <Modal dialogClassName="order-modal" show={show} onHide={handleClose}>
            
                <Modal.Header closeButton>
                    <Modal.Title> הזמנה מספר {orderId}</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <Order orderId={orderId} />
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                    סגור
                    </Button>
                </Modal.Footer>

            </Modal>
        )
    }

    const viewInvoice = (orderId) => {

        setOrderId(orderId);
        setInvoiceModal(true); 
    };
    
    const InvoiceModal = ({ show, orderId, handleClose }) => {
        
        return (
            <Modal show={show} onHide={handleClose}>
            
                <Modal.Header closeButton>
                </Modal.Header>
                
                <Modal.Body>
                    <Invoice orderId={orderId} />
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>סגור</Button>
                </Modal.Footer>

            </Modal>
        )
    }


    return (
        <>      
        <div className="container">
            <OrderTable />
        </div>
        <Duplicate show={showDuplicateModal} handleClose={handleClose} handleSave={handleSave} />
        <OrderModal show={showOrderModal} orderId={orderId} handleClose={handleClose} />
        <InvoiceModal show={showInvoiceModal} orderId={orderId} handleClose={handleClose} />
        </>
    )
}

export default Orders;
