import React, {useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Constants from "../Utilities/Constants";


const Payment = ({data, setComponent}) => { 

    console.log('payment: ',data);
    const account = Constants.TRANZILA_ACCOUNT;
    const cred_type = 1;  // 8=payments
    const maxpay    = 1;
    const totalOrder= data.totalOrder.toFixed(2);
    const orderId   = data.orderId;
    const navigate = useNavigate();

    useEffect(() => {
        const handleMessage = (event) => {
            // Ensure the message comes from a trusted source
            if (event.origin === window.location.origin && event.data.type === 'navigate') {
                navigate(event.data.path); // Navigate to the route sent by the iframe
            }
        };
    
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [navigate]);


    return (
        
        <div className="p-5 m-3">
            
            <div className="d-flex justify-content-between border-bottom border-success">
                <h4 className="">תשלום מאובטח</h4>
                <h4><button className="align-left" title="חזור" onClick={()=>setComponent('C')} >{'<<'}</button>
                </h4>
            </div>

            <div className="fw-bold text-success">
                <span>{totalOrder} ש"ח </span>
            </div>
            
            {/* <iframe src={`https://direct.tranzila.com/${account}/iframenew.php?currency=1&cred_type=${cred_type}&maxpay=${maxpay}&tranmode=V&sum=${totalOrder}&orderid=${orderId}&lang=il&nologo=0&trButtonColor=da4498`} 
                className="ccard" title="Credit card">
            </iframe> */}

            <iframe src={`http://localhost/blikemah/test-tranzila.asp?orderId=${orderId}&sum=${totalOrder}`}
                className="ccard" title="Credit card">
            </iframe>
            
        </div>
    
    )
}

export default Payment;