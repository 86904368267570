import React from "react";
import { useParams } from "react-router-dom";
import PageContent from "../Components/Content";


const ThankYou = () => {
    const { orderid } = useParams();

    return (
    
        <div className="container mt-5">
            <div className="wrapper cash">
                <h1>הזמנתך נקלטה בהצלחה</h1>
                <h4 className='text-center'>מספר הזמנה: {orderid}</h4>
                <PageContent furl='אישור-הזמנה' />
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            </div>
        </div>

    )
}

export default ThankYou;