import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
import Cookies from 'js-cookie';
import { GoogleLogin  } from "@react-oauth/google"


const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cellPhone, setCellPhone] = useState('');
    const [cellCode, setCellCode] = useState('');
    const [customerId, setCustomerId] = useState('');
    const { setCustomer, cart, setCart, setCartId, cartId } = useContext(AppContext);
    const [isChecked, setIsChecked] = useState(false);
    const [localCartId, setLocalCartId] = useState();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        setLocalCartId(cartId);
        const customerid = Cookies.get('customerid');
        customerid ? setIsChecked(true) : setIsChecked(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    

    const mailLogin = async () => {
        
        // Email & Password login
        const url = Constants.API_URL_GET_USER_LOGIN;
        
        try {
            const hash = await handleHash(password);
    
            const response = await axios.post(url,  {email, hash});
            await setCustomer(response.data);
            await handleCookie(response.data.customerId);
            await getCart(response.data.customerId);
            returnToPage();
        }
        catch (error) {
            if (error.response) {
                alert('שם משתמש או סיסמה לא נכונים');
            }
            else{
                console.error(error);
            }
        }
    }

    const handleCookie = async (id) => {
        
        (isChecked) ? 
            Cookies.set('customerid', id)
        : 
            Cookies.remove('customerid');
    }

    const getCart = async (cartid) => {
        
        if ((cart.length > 0) && window.confirm("להעביר מוצרים לסל?")) {
            moveLocalCart(localCartId, cartid);
        }
        else {
            if (cartid) {
                const url = `${Constants.API_URL_GET_CART}/${cartid}`;

                const response = await axios.get(url);
                setCart(response.data);
                setCartId(cartid);
            }
        }
    }

    const moveLocalCart = async (fromCartId,toCartId) => {

        const url = `${Constants.API_URL_MOVE_CART}?fromCartId=${fromCartId}&toCartId=${toCartId}`;
        console.log(url);
        try {
            const response = await axios.post(url);
            setCart(response.data);
        }
        catch (error) {
            console.error(error);
        }
    }

    const cellLogin = async () => {
        
        // Login by cellular code
        var verificationCode = cellCode;
        const url = Constants.API_URL_GET_USER_LOGIN;
        
        try {
            const response = await axios.post(url, { customerId, verificationCode });
            await setCustomer(response.data);
            await getCart(response.data.customerId);
            returnToPage();
        } 
        catch(err) {
            if (err.response) {
                alert('שם משתמש או סיסמה לא נכונים');
            }
            else {
                console.error(err);
            }
        }
    }

    const handleHash = async (text) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const sha256Hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return sha256Hash;
    }

    async function rememberme(e) {
        setIsChecked(e.target.checked);
    }
   
    async function sendCode()
    {
        const url = `${Constants.API_URL_GET_CODE}/?phone=${cellPhone}`;

        try {
            const response = await axios.get(url);
            console.log(response);
            setCustomerId(response.data);
            document.getElementById("getCell").style.display="none";
            document.getElementById("getCode").style.display="block";
        }
        catch(error) {
            if (error.response) {
                alert("מספר הטלפון לא קיים במערכת");
                
            } else {
                console.error(error);
            
            }
        }
    }

    function validateCellular() {
        const regex = /^0(5[012345678]|6[47]){1}(-)?[^0\D]{1}\d{6}$/;
        
        if (regex.test(cellPhone))
        {
            document.getElementById("phoneError").style.display= "none";
            sendCode();
            return true;
        } 
        else {
            document.getElementById("phoneError").style.display= "block";
            return false;
        }
    }
    
    const googleSuccess = async (tokenResponse) => {

        // You can send the response.tokenId to your backend for verification and further processing
        try{
            await axios.post(Constants.API_URL_GET_GOOGLE_LOGIN, {token: tokenResponse.credential})
                .then((response)=>{
                    setCustomer(response.data);
                    getCart(response.data.customerId);
                    returnToPage();
                });
        } 
        catch {
            alert("משתמש לא רשום במערכת.");
        }

    }
    
    const googleFailure = () => {
        alert('Login Failed:');
    }

    const returnToPage = () => {
        const from = location.state?.from?.pathname || '/';
        navigate(from, { replace: true });
    }

    
    return (
        <>
        <div className="container" >
        <h2 className="m-5 text-center">מתחברים לאתר</h2>

          {/* Login options tabs */}

        <div className="wrapper d-flex justify-content-center align-items-center" >
            <div className="w-100" >

                <ul className="nav nav-tabs p-0" id="login" role="tablist">
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100 fw-bold active" id="mail-tab" data-bs-toggle="tab" data-bs-target="#mail-tab-pane" type="button" role="tab" aria-controls="mail-tab-pane" aria-selected="true">כניסה באמצעות אימייל</button>
                    </li>
                    <li className="nav-item w-50" role="presentation">
                        <button className="nav-link w-100 fw-bold " id="cell-tab" data-bs-toggle="tab" data-bs-target="#cell-tab-pane" type="button" role="tab" aria-controls="cell-tab-pane" aria-selected="false">כניסה באמצעות SMS</button>
                    </li>
                </ul>

                <div className="tab-content mt-4" id="loginContent">
                    <div className="tab-pane fade show active" id="mail-tab-pane" role="tabpanel" aria-labelledby="mail-tab" tabIndex="0">
                        <form>
                                                
                            {/* <!-- Email input --> */}
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="email">אימייל</label>
                                <input type="email" id="email" className="form-control" autoComplete="email"
                                    onChange={(e)=>setEmail(e.target.value)} />
                            </div>
            
                            {/* <!-- Password input --> */}
                            <div className="form-outline mb-4">
                                <label className="form-label" htmlFor="password">סיסמה</label>
                                <input type="password" id="password" name="password" className="form-control" autoComplete="current-password"
                                     onChange={(e)=>setPassword(e.target.value)} />
                            </div>
            
                            {/* <!-- 2 column grid layout htmlFor inline styling --> */}
                            <div className="row mb-4">
                                <div className="col d-flex justify-content-center">
                                {/* <!-- Checkbox --> */}
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="rememberme"> זכור אותי </label>
                                    <input className="form-check-input" type="checkbox" value="" id="rememberme"          
                                    checked={isChecked} onChange={(e)=> rememberme(e) } />
                                </div>
                                </div>
            
                                <div className="col">
                                    <Link to="/ResetPassword">שכחתי סיסמה</Link>
                                </div>
                            </div>
            
                            {/* <!-- Submit button --> */}
                            <div className="d-grid mb-4">
                                <button type="button" name="mailLogin" className="btn btn-primary" onClick={mailLogin}>כניסה</button>
                            </div>
            
                        </form> 
                    </div>

                    <div className="tab-pane fade mt-4" id="cell-tab-pane" role="tabpanel" aria-labelledby="cell-tab" tabIndex="0">
                        <form name="signByCell" id="signByCell"  >
                            
                            <div id="getCell">
                                {/* <PhoneNumberInput /> */}
                                <div className="form-outline" >
                                    <label className="form-label" htmlFor="cellular">טלפון</label>
                                    <input type="tel" id="cellular" name="cellular" className="form-control" maxLength={11}
                                        onChange={(e)=>setCellPhone(e.target.value.trim())}/>
                                    <div className="mt-1" style={{height: "25px"}}>
                                        <span id="phoneError" className="invalid-feedback">יש להזין מספר טלפון תקין</span>
                                    </div>
                                    <div className="d-grid mb-4" style={{height: 115}}>
                                    </div>
                                </div>

                                <div className="d-grid mb-4">
                                    <button type="button" id="btn-send-phone" className="btn btn-primary" 
                                        onClick={()=>validateCellular()} >שלח לי קוד לנייד</button>
                                </div>
                            </div>
                                
                            <div id="getCode" className="hidden" >
                                {/* <!--  Accept code sent to cellular  --> */}
                                <div className="form-outline" >
                                    <label className="form-label" htmlFor="email">קוד מהנייד</label>
                                    <input name="code" id="cellCode" type="text" className="form-control"
                                        onChange={(e)=>setCellCode(e.target.value)}  />
                                </div>
                                <div id="warning2" className="col-md- ">
                                    קוד כניסה נשלח לנייד. נא הקש את הקוד שהתקבל בנייד ולחץ אישור.
                                </div>
                                <div className="d-grid mb-4" style={{height: 120}}>
                                </div>

                                <div className="d-grid mb-4">
                                    <input name="id" type="hidden" value="" />
                                    <button type="button" name="cellLogin"  className="btn btn-primary" onClick={cellLogin}>אישור</button>
                                </div>
                                

                            </div>
                        
                        </form>
                    </div>
                </div>

                {/* <!-- Register buttons --> */}
                <div className="text-center">
                    <p>לא רשום עדיין? <Link to="/Signup" className="ms-2"> הרשמה </Link></p>
                    
                    <p>או כניסה באמצעות:</p>
                    
                    <div className="d-flex justify-content-around mb-4">
                    
                        <GoogleLogin theme="filled_blue" onSuccess={googleSuccess} onError={googleFailure} />
                    
                        {/* <button type="button" className="btn btn-primary">כניסה באמצעות פייסבוק<i className="bi bi-facebook"></i></button> */}
                    
                    </div>
                </div>

            </div>
        </div>
        </div>
        </>
    )
}


export default SignIn; 