import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import Constants from "../Utilities/Constants";
import axios from "axios";


const Invoice = ({orderId}) => {
    const [site, setSite] = useState();
    const [ invoice, setInvoice ] = useState('');

    useEffect(() => {
    
        async function getInvoice() {
            const url = `${Constants.API_URL_GET_INVOICE}/${orderId}`;
    
            try {
                await axios.get(url)
                    .then((response) => setInvoice(response.data));
    
                //setTotal(response.data.items.reduce((a,v) =>  a = a + v.itemQtty * v.itemPrice , 0 ).toFixed(2));
            }
            catch(error) {
                // alert('העתק חשבון לא נמצא');
                console.log(error.message);
            }
        }
        
        getInvoice();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    useEffect(() => {

        const fetchSite = async () => {

            try {
                const response = await axios.get( Constants.API_URL_GET_SITE);
                setSite(response.data);

            } catch (error) {
                console.log(error);
            }
        };
    
        fetchSite();
    
    }, []);

    const InvoiceHeader = () => {
        
        // const item = invoice[0];
        
        return (

            <div className="d-flex justify-content-between bg-white p-4">
                
                <div>
                    <table>
                        <tbody>
                        <tr><td>{invoice.customerName}</td></tr>
                        <tr><td>{invoice.customerAddress}</td></tr>
                        <tr><td>{invoice.customerCity}</td></tr>
                        </tbody>
                    </table>
                </div>

                <div >
                    <table>
                        <tbody>
                        <tr><td className="ps-2"> מס הזמנה: </td><td> {invoice.orderId}</td></tr>
                        <tr><td> תאריך: </td><td> {format(invoice.invoiceDate,'dd/MM/yyy')}</td></tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        )
    }

    const InvoiceDetails = () => {

        // const DocumentID = "31"
        // const AccountKey = "302000"
        // const ItemKeyWTax = "1000"
        // const ItemKeyNoTax = "2000"
        // const TipKey = "3000"
        // const DeliveryKey = "3002"
        // const CouponKeyWTax = "4000"
        // const CouponKeyNoTax = "4002"
        // const ClubKeyWTax = "4004"
        // const ClubKeyNoTax = "4005"
        // const SpecialKeyWTax = "6000"
        // const SpecialKeyNoTax= "6001"
    
        // const taxFreeArray = [ItemKeyNoTax, CouponKeyNoTax, ClubKeyNoTax, SpecialKeyNoTax];

        // const newlist = invoice.invoiceItems.map((item) => {
        //     return ((taxFreeArray.includes(item.ItemKey)) 
        //         ? {...item, tax: 0, totalItem: (item.Price * item.Quantity).toFixed(2) } 
        //         : {...item, tax: (item.Price*0.17*item.Quantity).toFixed(2), totalItem: (item.Price*1.17 * item.Quantity).toFixed(2)});
        // });

        // var totalInvoice = newlist.reduce((a,v) =>  a = a + parseFloat(v.itemTotal) , 0 );
        // var totalTax = newlist.reduce((a,v) =>  a = a + parseFloat(v.tax) , 0 );

        return (
            <table className="table order-items">
                <thead>
                <tr>
                    <th>שם המוצר</th>
                    <th>כמות</th>
                    <th>מחיר</th>
                    <th>סה"כ</th>
                </tr>
                </thead>
                <tbody>
                    {invoice.invoiceItems.map((item, idx) => {
                        return (
                            <tr key={idx} className={(item.itemQtty < 0) ? 'fw-bold' : ''}>
                                <td >{item.productName}</td>
                                {/* <td >{item.Remarks}</td> */}
                                <td >
                                    {(item.itemQtty > 0) && 
                                    <>
                                        {item.itemQtty} {item.productUnit}
                                    </>}
                                </td>
                                <td >{item.itemPrice && (item.itemPrice*1.17).toFixed(2)}</td>
                                <td >{item.itemTotal.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                    
                    <tr>
                        <td className="title text-start" colSpan="3">סה"כ קנייה ללא מע"מ:</td>
                        <td className="content">{(invoice.totalInvoice-invoice.totalTax).toFixed(2)} ₪</td>
                    </tr>
                    <tr>
                        <td className="title text-start" colSpan="3">מע"מ:</td>
                        <td className="content">{invoice.totalTax.toFixed(2)} ₪</td>
                    </tr>
                    <tr>
                        <td className="title text-start" colSpan="3">סה"כ לתשלום:</td>
                        <td className="content">{invoice.totalInvoice.toFixed(2)} ₪</td>
                    </tr>
                </tbody>
            </table>
        )
    }


    return (
        <>
        {site && 
        
        <div className="row">
            <div className="col">
                <span className="fw-bold">{site.siteName}</span>
                <br/>
                הברזל 32 ת"א
                <br/>
               ח.פ. 123456789
            </div>
            
            <div className="col">
                <img src={`${Constants.IMAGE_URL}${site.logo}`} alt="Logo" />
            </div>
        </div>
        
        }

        {invoice ?
        <>
        <h3 className="m-4 text-center">חשבונית מס מס' {invoice.invoiceId}</h3>
        <div className="container">
            <div className="row">
                {invoice && <InvoiceHeader />}
                <br></br>
                {invoice && <InvoiceDetails /> }
            </div>
        </div>
        </>
        :
            <h3 className="m-4 text-center">חשבונית לא נמצאת</h3>
        }
        </>
    )  
}

export default Invoice;