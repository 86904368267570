import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate  } from "react-router-dom";
import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
// import PersonalMenu from "../Components/PersonalMenu"
import ChangePassword from "../Components/ChangePassword";
import Orders from "../Components/Orders";


const Profile = () => {

    const { customer, setCustomer } = useContext(AppContext);
    const phoneRef = useRef(null);
    const emailRef = useRef(null);
    const [yishuvim, setYishuvim] = useState([]);
    const navigate = useNavigate();
    const [ component, setComponent ] = useState('A');

    if (!customer) {
        alert("נא להיכנס למערכת");
        navigate('/Login');
    }

    useEffect(() => {

        async function Yishuvim() {
            try {
                const url = Constants.API_URL_YISHUVIM;
    
                const response = await axios.get(url);
                setYishuvim(response.data);
            }
            catch (error) {
                console.error(error);
            }
        }

        Yishuvim();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCustomer(values => ({...values, [name]: value}))
    }

    const handleSubmit = async (event) => {
        
        event.preventDefault();
   
        // submit profile form
        try {
            const url = Constants.API_URL_UPDATE_PROFILE;

            const json = await axios.post(url, customer);
            setCustomer(json.data);
            navigate("/");
        }
        catch (error) {
            if (error.response) {
                document.getElementById("submitError").style.display= "block";
            }
            else {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        }
        finally {
            //setLoading(false);
        }
    }

    function validateCellular(cellPhone) {
        const regex = /^0(5[012345678]|6[47]){1}(-)?[^0\D]{1}\d{6}$/;
        
        if (cellPhone === '' || regex.test(cellPhone))
        {
            document.getElementById("phoneError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("phoneError");
            field.style.display= "block";
            phoneRef.current.focus();
            return false;
        }
    }

    function validateEmail(email) {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        
        if (email === '' || regex.test(email))
        {
            document.getElementById("emailError").style.display= "none";
            return true;
        } 
        else {
            var field = document.getElementById("emailError");
            field.style.display= "block";
            emailRef.current.focus();
            return false;
        }
    }

    
    const PersonalMenu  = () => {

        return (
            <ul>
            <li><button className="btn p-3" onClick={() => setComponent('A')} >פרטים כלליים</button></li>
            <li><button className="btn p-3" onClick={() => setComponent('B')} >החלפת סיסמה</button></li>
            <li><button className="btn p-3" onClick={() => setComponent('C')} >ההזמנות שלי</button></li>
            </ul>
        )
    }

    const ProfileForm = () => {
        return (
            <div className="container">
                <div className="wrapper">
                    <form method="post">
                        <div className="text-danger"></div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName" className="form-label">שם פרטי</label>
                                <input name="firstName" id="firstName" className="form-control" value={customer.firstName}
                                    onChange={handleChange} />
                                <span className="text-danger"></span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName" className="form-label">שם משפחה</label>
                                <input name="lastName" id="lastName" className="form-control" value={customer.lastName}
                                    onChange={handleChange} />
                                <span className="text-danger"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="phoneNo" className="form-label">טלפון</label>
                                <input name="phoneNo" id="phoneNo" className="form-control" ref={phoneRef} value={customer.phoneNo}
                                    onChange={handleChange} onBlur={(e)=>validateCellular(e.target.value)} />
                                <span id="phoneError" className="text-danger">נא הקש מספר טלפון תקין</span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="email" className="form-label">דואר אלקטרוני</label>
                                <input name="email" id="email" className="form-control"  ref={emailRef} value={customer.email}
                                    onChange={handleChange} onBlur={(e)=>validateEmail(e.target.value)} />
                                <span id="emailError" className="text-danger">נא הקש כתובת דואר אלקטרוני תקין</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="address" className="form-label">כתובת</label>
                                <input name="address" id="address" className="form-control" onChange={handleChange} value={customer.address}/>
                                <span className="text-danger"></span>
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="yishuvId" className="form-label">ישוב</label>
                                <select name="yishuvId" id="yishuvId" className="form-select" value={customer.yishuvId} onChange={handleChange} >
                                    {
                                        yishuvim.map((yishuv) => {
                                        return (<option key={yishuv.yishuvId} value={yishuv.yishuvId}>
                                            {yishuv.yishuvName}</option>)
                                        })
                                    }
                                    </select>
                                <span className="text-danger"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="entrance" className="form-label">כניסה/קומה/דירה</label>
                                <input name="entrance" id="entrance" className="form-control" onChange={handleChange} value={customer.entrance}/>
                                <span className="text-danger"></span>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label htmlFor="intercom" className="form-label">אינטרקום</label>
                                <input name="intercom" id="intercom" className="form-control" onChange={handleChange} value={customer.intercom}/>
                                <span className="text-danger"></span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="notes" className="form-label">הערות לכתובת</label>
                                <input name="notes" id="notes" className="form-control" onChange={handleChange} value={customer.notes}/>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <span id="submitError" className="invalid-feedback">משתמש קיים במערכת</span>
                            </div>
                        </div>

                        <div className="buttons mt-4">
                            <input type="submit" value="שמור" className="btn btn-primary" onClick={handleSubmit} />
                            <Link to='/' className="btn btn-secondary">בטל</Link>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    return (
        <>
        <h3 className="m-5 text-center">האיזור האישי שלי</h3>
        <div className="container">
            <div className="row">
                <div className="col-md-3 vertical-nav">
                    <PersonalMenu />
                </div>
                <div className="col-md-9">
                        {console.log('component',component)}
                { 
                    {
                    'A':
                        <ProfileForm />,
                    'B':
                        <ChangePassword />,
                    'C':
                        <Orders />
                    }[component]
                }
                </div>
            </div>
        </div>
        </>
    )  
}



export default Profile;
