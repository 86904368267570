import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import Constants from "../Utilities/Constants";


const SuccessPage = () => {
    const location = useLocation();
    const [data, setData] = useState({});
    // const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const receivedData = {};
        for (let param of params.entries()) {
            receivedData[param[0]] = param[1];
        }
        setData(receivedData);
        updateOrder(receivedData);
    
        window.parent.postMessage(
            {
                type: 'navigate',
                path: `/ThankYou/${receivedData.orderid}`
            },
            window.location.origin // Ensure it's sent to the same origin
        );

    }, [location]);


    const updateOrder = async (data) => {
        
        try {
            const url = `${Constants.API_URL_UPDATE_ORDER}`;
            await axios.post(url, data);
            
        } catch(error) {
            console.error(error);
        }

    }


    return (
        <div>
            מעבד...
        </div>
    );
};

export default SuccessPage;
