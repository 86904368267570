import React, { useState, useEffect } from "react";
// import { AppContext } from "../Components/AppContext";
import Constants from "../Utilities/Constants";
import axios from "axios";
import { format } from "date-fns";


const Order = ({orderId}) => {
    const [ order, setOrder ] = useState('');

    useEffect(() => {

        const getOrder = async () => {

            try {
                const url = `${Constants.API_URL_GET_ORDER}/${orderId}`;
                const response = await axios.get(url);
                setOrder(response.data);
            }
            catch(error) {
                alert('הזמנה לא נמצאה');
                console.log(error.message);
            }
        }
    
        getOrder();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    const OrderHeader = () => {
        
        return (
            <div className="d-flex justify-content-between">
                
                <div>
                    <table className="table table-sm">
                        <tbody>
                        <tr><td>{order.customer.customerName}</td></tr>
                        <tr><td>{order.customer.phoneNo}</td></tr>
                        <tr><td>{order.phoneNo}</td></tr>
                        </tbody>
                    </table>
                </div>
                
                <div >
                    <table className="table table-sm">
                        <tbody>
                        {/* <tr><td> מס הזמנה: </td><td> {order.orderId}</td></tr> */}
                        <tr><td> תאריך הזמנה: </td><td> {format(order.orderDate,'dd/MM/yyy')}</td></tr>
                        <tr><td> תאריך משלוח: </td><td> {format(order.deliveryDate,'dd/MM/yyy HH:mm')}</td></tr>
                        <tr><td>כתובת למשלוח: </td><td> {order.address}  {order.yishuv.yishuvName}</td></tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        )
    }

    const OrderFooter = () => {
        return (
            <div className="text-end">
                <div className="fw-bold">הערות להזמנה:</div>
                <div>{order.notes}</div>
                {order.replaceItem && <div>במקרה של חוסר, הוסיפו מוצר חלופי. אין צורך להתקשר.</div>}
                {/* {order.leaveOutside && <div>נא השאירו את הארגזים מחוץ לדלת</div>} */}
                <br />
                <span>שולם בכרטיס המסתיים בספרות  {order.cardNo}</span>
            </div>
        )
    }

    const OrderDetails = () => {

        return (

                <table className="table table-striped order-items">
                    <thead  className="text-center;">
                    <tr>
                        <th>תמונה</th>
                        <th >שם המוצר</th>
                        <th>כמות</th>
                        <th>סה"כ</th>
                    </tr>
                    </thead>
                    <tbody>
                        {order.orderItems.map((item) => {
                        return (
                            <tr key={item.itemId} >
                                <td className="image" ><img src={`${Constants.SITE_URL}${item.product.image}`} alt={item.product.productName}/></td>
                                <td  >
                                    {item.product.productName}
                                    <div style={{fontSize: 12}}>
                                        {item.product.price.toFixed(2)} ל{item.unit.unitName}
                                    </div>
                                    {/* <div className="smartphone notes text-primary">{item.notes}</div> */}
                                    {item.product.special &&
                                    item.ingredients.map((ing, idx) => {
                                        return (
                                        <div key={idx} className="text-success">{ing.name}</div>
                                        )
                                    })}
                                </td>
                                <td>{item.itemQtty} {item.unit.unitName}</td>
                                <td >{(item.itemPrice).toFixed(2)}</td>
                            </tr>
                        )
                        })}
                                                                            
                        <tr>
                            <td className="title" colSpan="4" >{order.orderItems.length} מוצרים בהזמנה</td> 
                        </tr>
                        <tr>    
                            <td className="title text-start" colSpan="3" >סה"כ קנייה:</td>
                            <td className="content">{order.totalCart.toFixed(2)}</td>
                        </tr>
                        {order.clubDiscount > 0 && 
                        <tr>
                            <td className="text-start" colSpan="3"> הנחת מועדון:</td>    
                            <td className="content" >{order.clubDiscount.toFixed(2)}-</td>
                        </tr>
                        }
                        { order.couponCode &&
                        <tr>
                            <td className="title text-start" colSpan="3" >קופון הנחה:</td>
                            <td className="content">{order.couponDiscount}-</td>
                        </tr>
                        }
                        { order.tip > 0  &&
                        <tr>
                            <td className="title text-start" colSpan="3" >טיפ:</td>
                            <td className="content">{order.tip.toFixed(2)}</td>
                        </tr>
                        }
                        { order.deliveryFee > 0 && 
                        <tr>
                            <td className="title text-start" colSpan="3" >משלוח:</td>
                            <td className="content">{order.deliveryFee.toFixed(2)}</td>
                        </tr>
                        }
                        <tr>
                            <td className="title text-start" colSpan="3">סה"כ לתשלום:</td>
                            <td className="content">{order.totalOrder && order.totalOrder.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>

        )
    }


    return (
        <>
            {order && <OrderHeader />}
            <br></br>
            {order && <OrderDetails /> }
            <br></br>
            {order && <OrderFooter /> }
        </>
    )  
}

export default Order;