const API_URL_DEVELOPMENT = "https://localhost:7149";
// const API_URL_DEVELOPMENT = "https://api.ozaviad.co.il";
const API_URL_PRODUCTION = "https://supercommerce.azurewebsites.net";
// const API_URL_DEVELOPMENT = "https://localhost/SuperAPI";
// const API_URL_DEVELOPMENT ="https://supercommerce.azurewebsites.net";

const API_IMAGES_PRODUCTION = "https://admin.ozaviad.co.il";
const API_IMAGES_DEVELOPMENT = "https://localhost:7149";

const ENDPOINTS = {
    GET_SITE: "Site",
    GET_PRODUCTS: "Product/Search",
    GET_GALLERY: "Gallery",
    GET_MENU: "Menu",
    // GET_USER_IP: "Customer/IP",
    GET_USER_LOGIN: "Customer/Login",
    GET_GOOGLE_LOGIN: "Customer/GoogleLogin",
    GET_CATEGORIES: "Category/GetCategories",
    GET_SUB_CATEGORIES: "Category/GetSubCategories",
    CATEGORY_TITLE: "Category/Title",
    GET_PRODUCTS_BY_CATEGORY: "Category",
    QUERY: "Product/Query",
    GET_PRODUCT: "Product",
    GET_CUSTOMER: "Customer/Get",
    GET_CODE: "Customer/Code",
    RESET_PASSWORD: "Customer/Reset",
    REGISTER: "Customer/Register",
    UPDATE_PROFILE: "Customer/UpdateProfile",
    GET_CART: "Cart/Get",
    GET_STOCK: "Cart/Stock",
    UPDATE_CART: "Cart/Update",
    LOAD_CART: "Cart/LoadCart",
    MOVE_CART: "Cart/MoveCart",
    YISHUVIM: "Yishuv/GetYishuvim",
    GET_ORDERS: "Order/Orders",
    GET_ORDER: "Order",
    ADJUST_ORDER: "Cart/AdjustOrder",
    GET_COUPON: "Customer/Coupon",
    CREATE_ORDER: "Order/NewOrder",
    UPDATE_ORDER: "Order/UpdateOrder",
    GET_INVOICE: "Invoice",
    GET_DELIVERY_WINDOWS: "Delivery",
    GET_INGREDIENTS: "Cart/Ingredients",
    GET_PAGE: "Page",
    SEND_EMAIL: "SendEmail",
};

const development = {
    TRANZILA_ACCOUNT: "hsukhely",
    SITE_URL: API_URL_DEVELOPMENT,
    IMAGE_URL: API_IMAGES_DEVELOPMENT,
    API_URL_GET_SITE:`${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_SITE}`, 
    API_URL_GET_PRODUCTS:`${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_PRODUCTS}`, 
    API_URL_GET_GALLERY: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_GALLERY}`,
    API_URL_GET_MENU: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_MENU}`,
    // API_URL_GET_USER_IP: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_USER_IP}`,
    API_URL_GET_USER_LOGIN: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_USER_LOGIN}`,
    API_URL_GET_GOOGLE_LOGIN: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_GOOGLE_LOGIN}`,
    API_URL_GET_CATEGORIES: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_CATEGORIES}`,
    API_URL_GET_SUB_CATEGORIES: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_SUB_CATEGORIES}`,
    API_URL_GET_CATEGORY_TITLE: `${API_URL_DEVELOPMENT}/${ENDPOINTS.CATEGORY_TITLE}`,
    API_URL_GET_PRODUCTS_BY_CATEGORY: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_PRODUCTS_BY_CATEGORY}`,
    API_URL_QUERY: `${API_URL_DEVELOPMENT}/${ENDPOINTS.QUERY}`,
    API_URL_GET_PRODUCT: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_PRODUCT}`,
    API_URL_GET_CUSTOMER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_CUSTOMER}`,
    API_URL_GET_CODE: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_CODE}`,
    API_URL_RESET_PASSWORD: `${API_URL_DEVELOPMENT}/${ENDPOINTS.RESET_PASSWORD}`,
    API_URL_REGISTER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.REGISTER}`,
    API_URL_UPDATE_PROFILE: `${API_URL_DEVELOPMENT}/${ENDPOINTS.UPDATE_PROFILE}`,
    API_URL_GET_CART: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_CART}`,
    API_URL_GET_STOCK: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_STOCK}`,
    API_URL_UPDATE_CART: `${API_URL_DEVELOPMENT}/${ENDPOINTS.UPDATE_CART}`,
    API_URL_LOAD_CART: `${API_URL_DEVELOPMENT}/${ENDPOINTS.LOAD_CART}`,
    API_URL_MOVE_CART: `${API_URL_DEVELOPMENT}/${ENDPOINTS.MOVE_CART}`,
    API_URL_YISHUVIM: `${API_URL_DEVELOPMENT}/${ENDPOINTS.YISHUVIM}`,
    API_URL_GET_ORDERS: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_ORDERS}`,
    API_URL_GET_ORDER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_ORDER}`,
    API_URL_ADJUST_ORDER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.ADJUST_ORDER}`,
    API_URL_GET_COUPON: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_COUPON}`,
    API_URL_CREATE_ORDER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.CREATE_ORDER}`,
    API_URL_UPDATE_ORDER: `${API_URL_DEVELOPMENT}/${ENDPOINTS.UPDATE_ORDER}`,
    API_URL_GET_INVOICE: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_INVOICE}`,
    API_URL_GET_DELIVERY_WINDOWS: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_DELIVERY_WINDOWS}`,
    API_URL_GET_INGREDIENTS: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_INGREDIENTS}`,
    API_URL_GET_PAGE: `${API_URL_DEVELOPMENT}/${ENDPOINTS.GET_PAGE}`,
    API_URL_SEND_EMAIL: `${API_URL_DEVELOPMENT}/${ENDPOINTS.SEND_EMAIL}`,
};

const production = {
    TRANZILA_ACCOUNT: "hsukhely",
    SITE_URL: API_URL_PRODUCTION,
    IMAGE_URL: API_IMAGES_PRODUCTION,
    API_URL_GET_SITE:`${API_URL_PRODUCTION}/${ENDPOINTS.GET_SITE}`, 
    API_URL_GET_PRODUCTS:`${API_URL_PRODUCTION}/${ENDPOINTS.GET_PRODUCTS}`, 
    API_URL_GET_GALLERY: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_GALLERY}`,
    API_URL_GET_MENU: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_MENU}`,
    // API_URL_GET_USER_IP: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_USER_IP}`,
    API_URL_GET_USER_LOGIN: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_USER_LOGIN}`,
    API_URL_GET_GOOGLE_LOGIN: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_GOOGLE_LOGIN}`,
    API_URL_GET_CATEGORIES: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_CATEGORIES}`,
    API_URL_GET_SUB_CATEGORIES: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_SUB_CATEGORIES}`,
    API_URL_GET_CATEGORY_TITLE: `${API_URL_PRODUCTION}/${ENDPOINTS.CATEGORY_TITLE}`,
    API_URL_GET_PRODUCTS_BY_CATEGORY: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_PRODUCTS_BY_CATEGORY}`,
    API_URL_QUERY: `${API_URL_PRODUCTION}/${ENDPOINTS.QUERY}`,
    API_URL_GET_PRODUCT: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_PRODUCT}`,
    API_URL_GET_CUSTOMER: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_CUSTOMER}`,
    API_URL_GET_CODE: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_CODE}`,
    API_URL_RESET_PASSWORD: `${API_URL_PRODUCTION}/${ENDPOINTS.RESET_PASSWORD}`,
    API_URL_REGISTER: `${API_URL_PRODUCTION}/${ENDPOINTS.REGISTER}`,
    API_URL_UPDATE_PROFILE: `${API_URL_PRODUCTION}/${ENDPOINTS.UPDATE_PROFILE}`,
    API_URL_GET_CART: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_CART}`,
    API_URL_GET_STOCK: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_STOCK}`,
    API_URL_UPDATE_CART: `${API_URL_PRODUCTION}/${ENDPOINTS.UPDATE_CART}`,
    API_URL_LOAD_CART: `${API_URL_PRODUCTION}/${ENDPOINTS.LOAD_CART}`,
    API_URL_MOVE_CART: `${API_URL_PRODUCTION}/${ENDPOINTS.MOVE_CART}`,
    API_URL_YISHUVIM: `${API_URL_PRODUCTION}/${ENDPOINTS.YISHUVIM}`,
    API_URL_GET_ORDERS: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_ORDERS}`,
    API_URL_GET_ORDER: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_ORDER}`,
    API_URL_ADJUST_ORDER: `${API_URL_PRODUCTION}/${ENDPOINTS.ADJUST_ORDER}`,
    API_URL_GET_COUPON: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_COUPON}`,
    API_URL_CREATE_ORDER: `${API_URL_PRODUCTION}/${ENDPOINTS.CREATE_ORDER}`,
    API_URL_UPDATE_ORDER: `${API_URL_PRODUCTION}/${ENDPOINTS.UPDATE_ORDER}`,
    API_URL_GET_INVOICE: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_INVOICE}`,
    API_URL_GET_DELIVERY_WINDOWS: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_DELIVERY_WINDOWS}`,
    API_URL_GET_INGREDIENTS: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_INGREDIENTS}`,
    API_URL_GET_PAGE: `${API_URL_PRODUCTION}/${ENDPOINTS.GET_PAGE}`,
    API_URL_SEND_EMAIL: `${API_URL_PRODUCTION}/${ENDPOINTS.SEND_EMAIL}`,
};


const Constants = process.env.NODE_ENV === "development" ? development : production;

export default Constants;

